<template>
  <div class="editor">
    <ckeditor
      :id="id"
      :ref="id"
      :value="value"
      :editor="editor"
      @input="handleContent"
      @ready="onEditorReady"
      :config="editorConfig"
    />
  </div>
</template>
<style>
  .ck.ck-reset_all, .ck.ck-reset_all * {
    font: unset;
  }
  .ck-content { min-height:250px; max-height: 750px; }
  .ck-inspector {
    direction: ltr;
  }
</style>
<script>
import "@/common/plugins/ckeditor";
import "@ckeditor/ckeditor5-build-classic/build/translations/ug";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import ApiService from "../../../common/api.service";
export default {
  name: "Editor",
  props: {
    value: {
      type: String,
      default: ""
    },
    height: {
      type: null,
      default: "250px"
    },
    id: {
      type: String,
      default: "editor"
    }
  },
  components: {},
  watch: {
    value: function value(val) {
      let editor = this.editorInstance;
      // console.log(editor);
      // console.log(editor);
      /*if ( val !== editor.getContent() ){
        editor.setContent(val);
      }*/
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: "ug",
        alignment: {
          options: [ 'left', 'right', 'center', 'justify' ]
        },
        toolbar: [
          "heading", //段落
          "|", //分隔
          "bold", //加粗
          "italic", //倾斜
          // "link", //超链接
          "|",
          "bulletedList", //项目列表
          "numberedList", //编号列表
          "blockQuote", //块引用
          "|",
          "undo", //撤销
          "redo", //重做
          "|",
          "imageStyle:full", //图片通栏显示
          "imageStyle:side", //图片侧边显示
          "imageTextAlternative", //更换图片替换文本
          "imageUpload", //插入图像
          "|",
          "insertTable",
          "|",
          "indent",
          "outdent",
        ] //工具栏显示
      },
      editorInstance: null
    };
  },
  methods: {
    handleContent(content) {
      this.$emit("input", content);
    },
    handleUpload: async data => {
      let res = {};
      ApiService.setHeader();
      await ApiService.post(`/image-upload`, data)
        .then(response => {
            res = {
              default: response.data.url,
              alt: response.data.path
            };
        });
      return res;
    },
    onEditorReady(editor) {
      // CKEditorInspector.attach(editor);
      // console.log(editor);
      this.editorInstance = editor;
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return {
          upload: async () => {
              const data = new FormData();
              data.append("file", await loader.file);
              data.append("name", "file");
              data.append("strategy", "editor");
              return await this.handleUpload(data);
          }
        };
      };
    },
    setContent(content = ''){
      this.editorInstance.setContent(content);
    }
  }
}
</script>
